.front-page-expand {
  margin: 0;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}
.label-balance {
  margin-bottom: 4px;
  margin-top: 4px;
}
.privacy-policy {
  color: black;
}
.word-wrap {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}
.sign-up {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 75%;
  /* border: 1px solid green; */
}
@media screen and (min-width: 900px) {
  .sign-up {
    width: 75%;
  }
}
@media screen and (min-width: 1500px) {
  .sign-up {
    width: 50%;
  }
}
@media screen and (min-width: 730px) {
  .sign-in-text-box {
    left: 50%;
    right: 50%;
    width: 90%;
    height: 90%;
    margin: auto;
  }
}
.sign-in-box .lead {
  color: white;
}
#root {
  min-height: 100vh;
}
.regular-box {
  margin-top: 1.5rem !important;
}
@media screen and (min-width: 900px) {
  .sign-in-area-outer {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 75%;
    /* border: 1px solid green; */
  }
}
@media screen and (min-width: 1200px) {
  .sign-in-area-outer {
    width: 75%;
  }
}
@media screen and (max-height: 375px) {
  .sign-in-area-outer {
    height: 100%;
  }
}
@media screen and (min-width: 900px) {
  .sign-in-area-inner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 75%;
    /* border: 1px solid green; */
  }
}
@media screen and (min-width: 900px) {
  .sign-in-area-inner {
    width: 75%;
  }
}
@media screen and (max-width: 767px) {

  .sign-in-area-inner{
    content: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .compact-box {
    display: none;
  }
}
@media screen and (max-height: 690px) {
  .compactor-box {
    display: none;
  }
  .regular-box {
    text-align: center;
    padding-bottom: 35px;
  }
  .sign-in-area-inner {
    display: contents;
  }
  .sign-in-card {
    margin-left: auto;
    margin-right: auto;
    width: 35%;
  }
}

.login-card {
  margin: 10;
}
.browser-warning {
  max-width: 400px;
  color: white;
}

.language-selector {
    position: absolute;    
    top: 0;
    right: 0;
    padding: 5px;
    z-index: 99;
}
.background-sign-in {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: var(--dark);
  z-index: -1;
  overflow: hidden;
}

.sign-in-text-box h2,
.sign-in-text-box p {
  color: white;
}

.message-list tbody {
  border: none !important;
}
.editor-class {
  background-color: var(--dark);
  padding: 1rem;
  border: 1px solid white;
}

.sign-in-card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

@media (min-width: 544px) {
  .footer-auth {
    color: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 1rem;
  } /*1rem = 16px*/

  .sign-in-card-body {
    padding: 0rem;
  }
}

@media (max-width: 500px) {
  .language-selector {
    position: relative;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */

@media (min-width: 768px) {

  .footer-auth {
    color: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 1rem;
  } /*1rem = 16px*/
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .footer-auth {
    color: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 0.75rem;
  } /*1rem = 16px*/
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1160px) {
  .footer-auth {
    color: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 0.75rem;
  } /*1rem = 16px*/
}

.custom-background .ql-editor {
  background-color: var(--dark);
  color: white
}


.ql-snow{
  .ql-picker{
      &.ql-size{
          .ql-picker-label,
          .ql-picker-item{
              &::before{
                  content: attr(data-value) !important;
              }
          }
      }
  }
}

.ql-snow{
  .ql-picker{
      &.ql-font{
          .ql-picker-label,
          .ql-picker-item{
              &::before{
                  content: attr(data-value) !important;
              }
          }
      }
  }
}

.ql-snow .ql-picker-label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ql-snow .ql-editor {
  min-height: 15rem;
  background: #fff;
  font-family: "GT-Walsheim-Pro";
  font-size: 8pt;
  color: black;
}
